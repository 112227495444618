<template>
  <v-overlay :value="showMe" color="white" z-index="100000">
    <v-avatar size="64" color="color3Text" style="margin-bottom: 70vh;">
      <v-progress-circular indeterminate size="48" color="color3"></v-progress-circular>
    </v-avatar>
  </v-overlay>
</template>

<script>
export default {
  props: ['showMe'],
  data () {
    return {
      test: true
    }
  }
}
</script>

<style>
  .noShadow {
    box-shadow: none;
  }
</style>
